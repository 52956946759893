.date-text-field .MuiFormHelperText-root {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: -3px !important;
  margin-bottom: -8px !important;
  height: 16px !important;
  font-size: 0.65rem !important;
}

.privacy-version-text-field .MuiFormHelperText-root {
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-top: -3px !important;
  margin-bottom: -13px !important;
  height: 16px !important;
  font-size: 0.65rem !important;
}
