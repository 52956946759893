@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 900;
  src: local("SF Pro Display"),
    url(../assets/fonts/SF-Pro-Display-Black.woff) format("woff");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: italic;
  font-weight: 900;
  src: local("SF Pro Display"),
    url(../assets/fonts/SF-Pro-Display-BlackItalic.woff) format("woff");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 700;
  src: local("SF Pro Display"),
    url(../assets/fonts/SF-Pro-Display-Bold.woff) format("woff");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: italic;
  font-weight: 700;
  src: local("SF Pro Display"),
    url(../assets/fonts/SF-Pro-Display-BoldItalic.woff) format("woff");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 800;
  src: local("SF Pro Display"),
    url(../assets/fonts/SF-Pro-Display-Heavy.woff) format("woff");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: italic;
  font-weight: 800;
  src: local("SF Pro Display"),
    url(../assets/fonts/SF-Pro-Display-HeavyItalic.woff) format("woff");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 300;
  src: local("SF Pro Display"),
    url(../assets/fonts/SF-Pro-Display-Light.woff) format("woff");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: italic;
  font-weight: 300;
  src: local("SF Pro Display"),
    url(../assets/fonts/SF-Pro-Display-LightItalic.woff) format("woff");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  src: local("SF Pro Display"),
    url(../assets/fonts/SF-Pro-Display-Medium.woff) format("woff");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: italic;
  font-weight: 500;
  src: local("SF Pro Display"),
    url(../assets/fonts/SF-Pro-Display-MediumItalic.woff) format("woff");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  src: local("SF Pro Display"),
    url(../assets/fonts/SF-Pro-Display-Regular.woff) format("woff");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: italic;
  font-weight: 400;
  src: local("SF Pro Display"),
    url(../assets/fonts/SF-Pro-Display-RegularItalic.woff) format("woff");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 600;
  src: local("SF Pro Display"),
    url(../assets/fonts/SF-Pro-Display-Semibold.woff) format("woff");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: italic;
  font-weight: 600;
  src: local("SF Pro Display"),
    url(../assets/fonts/SF-Pro-Display-SemiboldItalic.woff) format("woff");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 200;
  src: local("SF Pro Display"),
    url(../assets/fonts/SF-Pro-Display-Thin.woff) format("woff");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: italic;
  font-weight: 200;
  src: local("SF Pro Display"),
    url(../assets/fonts/SF-Pro-Display-ThinItalic.woff) format("woff");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 100;
  src: local("SF Pro Display"),
    url(../assets/fonts/SF-Pro-Display-Ultralight.woff) format("woff");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: italic;
  font-weight: 100;
  src: local("SF Pro Display"),
    url(../assets/fonts/SF-Pro-Display-UltralightItalic.woff) format("woff");
}
