.journeys-list-checkbox {
  opacity: 0;
}

.journeys-list-checkbox:hover {
  opacity: 0.6;
}

.animation-div {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #5562b4;
  position: fixed;
  z-index: 10;
  transition: top 400ms, left 400ms, opacity 300ms;
  cursor: pointer;
}
