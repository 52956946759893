* {
  transition: background-color 150ms;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin: 0;
}

button {
  cursor: pointer;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-title {
  font-size: 1.1rem !important;
}

.card-subtitle {
  font-size: 1rem !important;
}

.horizontal-scroll::-webkit-scrollbar {
  display: none;
}

h4 {
  font-size: 1.8rem !important;
  font-weight: 600 !important;
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin-bottom: 0 !important;
}

.two-lines-overflow {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.drawer-item .MuiTypography-root {
  font-size: 0.9rem !important;
}

.MuiTabs-scroller {
  overflow-x: overlay !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.MuiTabs-scroller::-webkit-scrollbar {
  display: none;
}

.hide-scrollbars {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scrollbars::-webkit-scrollbar {
  display: none;
}

.marvel-device {
  font-family: "SF Pro Display" !important;
}

// animated aworld logo
.animated-logo {
  animation: rotateAnimation 3s linear infinite;
  transform-style: preserve-3d;
}

@keyframes rotateAnimation {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(-360deg);
  }
}

@keyframes spinner {
  from {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(-90deg);
  }
  to {
    transform: rotateY(0deg);
  }
}

.MuiAccordion-rounded::before {
  opacity: 0;
}

.filters-stack::-webkit-scrollbar {
  display: none;
}

.filters-stack {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

@media screen and (max-width: 414px) {
  *::-webkit-scrollbar {
    display: none;
  }

  * {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

/* preview */
.preview {
  font-family: "SF Pro Display" !important;
}
