.image-dropzone-container {
  color: #666666;
  border: 1px solid #c4c4c4;
  cursor: pointer;
}

.image-dropzone-container-disabled {
  color: #9e9e9f;
  border: 1px solid #c4c4c4;
  cursor: default;
}

.image-dropzone-container-error {
  color: #666666;
  border: 1px solid #d3302f;
  cursor: pointer;
}

.image-dropzone-container-error-prismic {
  color: #666666;
  border: 1px solid #f26161;
  cursor: pointer;
}

.image-dropzone-container:hover {
  border: 1px solid #222222;
}
