.group-clickable-item {
  transition: 0ms;
}

.group-clickable-item:hover {
  background-color: #f5f6f8;
  cursor: pointer;
}

.members-list-checkbox {
  opacity: 0;
}

.members-list-checkbox:hover {
  opacity: 0.6;
}

.animation-div {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  background-color: #5562b4;
  position: fixed;
  z-index: 10;
  transition: top 400ms, left 400ms, opacity 300ms;
  cursor: pointer;
}

.MuiSpeedDial-fab {
  width: 50px;
  height: 50px;
  min-height: 50px;
}

.MuiSpeedDial-root {
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiSpeedDial-root:hover .MuiSpeedDial-fab {
  transform: rotate(45deg) !important;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
