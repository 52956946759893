.date-picker {
  width: 100%;
}

.date-picker .MuiInputBase-root {
  height: 40px !important;
}

.date-picker .MuiInputBase-input {
  height: 40px !important;
  padding-top: 0;
  padding-bottom: 0;
}

.date-picker label {
  margin-top: -7px;
  transition: 160ms;
}

.date-picker .MuiInputLabel-shrink {
  margin-top: 0;
  transition: 160ms;
}
