.form-control-label .MuiTypography-root {
  font-size: 0.75rem !important;
}

// reload indicator animation
.rotate-clockwise {
  animation: rotation-clockwise 1s infinite linear;
}

.rotate-clockwise-slow {
  animation: rotation-clockwise 2.5s infinite linear;
}

.rotate {
  animation: rotation 1s infinite linear;
}

.rotate-slow {
  animation: rotation 2.5s infinite linear;
}

@keyframes rotation-clockwise {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(359deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.search-by-id-text-field .MuiFormHelperText-root {
  position: absolute;
  bottom: -22px;
}
