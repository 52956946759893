.calendar-item {
  transition: 100ms;
}

.calendar-item:hover {
  background-color: #ebebeb;
  transition: 100ms;
}

.test {
  border: 1px solid #c4c4c4 !important;
  border-radius: 0 !important;
}
