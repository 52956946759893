.color-picker {
  width: 100%;
  min-width: 100%;
  height: 160px;
  max-height: 160px;
}

.color-input {
  border-radius: 8px;
  border: 1px solid #dce0e4;
  height: 40px;
  padding: 8.5px 14px;
  font-size: 1rem;
  font-weight: 400;
}

.color-input:hover {
  border-color: black;
}

.color-input:focus {
  outline: none;
  border-color: black;
}

.color-input-disabled {
  border-radius: 8px;
  border: 1px solid #e5e8eb;
  color: #939eaa;
  height: 40px;
  padding: 8.5px 14px;
  font-size: 1rem;
  font-weight: 400;
  background-color: white;
}
