.editor {
  padding-left: 11px;
  padding-right: 11px;
}

.editor-disabled {
  padding-left: 11px;
  padding-right: 11px;
  color: #9e9e9f;
}

.toolbar {
  border: none;
  margin-bottom: -6px;
  margin-top: 5px;
  background-color: transparent;
}

.toolbar-disabled {
  border: none;
  margin-bottom: -6px;
  margin-top: 5px;
  background-color: transparent;
}

.toolbar-disabled .rdw-option-wrapper {
  cursor: default;
  opacity: 0.3;
}

.toolbar-disabled .rdw-option-wrapper:hover {
  box-shadow: none;
}

.wrapper {
  border-radius: 10px;
  border: 1px solid rgba(145, 158, 171, 0.32);
}

.wrapper-disabled {
  border-radius: 10px;
  border: 1px solid #c4c4c4;
  cursor: default;
}

.wrapper:hover {
  border: 1px solid #000000;
}

.wrapper-focus {
  border-radius: 10px;
  border: 1px solid #000000;
  margin: 0px;
}

.wrapper-error {
  border-radius: 10px;
  border: 1px solid #d3302f;
}

.wrapper-error-prismic {
  border-radius: 10px;
  border: 1px solid #f26161;
}

.wrapper-error-focus {
  border-radius: 10px;
  border: 2px solid #d3302f;
  margin: -1px;
}

.wrapper-error-focus-prismic {
  border-radius: 10px;
  border: 2px solid #f26161;
  margin: -1px;
}
