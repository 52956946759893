.image-picker-container {
  border: 1px solid #dce0e4;
  cursor: pointer;
}

.image-picker-container:hover {
  border: 1px solid #222222;
}

.image-picker-container-disabled {
  border: 1px solid #dce0e4;
}

.image-picker-container-error {
  border-radius: 4px;
  border: 1px solid #d3302f;
  cursor: pointer;
}

.image-picker-container-error-prismic {
  border-radius: 4px;
  border: 1px solid #f26161;
  cursor: pointer;
}
